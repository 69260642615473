import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Button, Typography, Icon, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MoreVert } from '@material-ui/icons';
import { Link as Scroll } from 'react-scroll'
import { Menu as MenuIcon } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: "column",
        height: '100vh',
        
    },
    appBarTitle: {
        flexGrow: '1',
        opacity: "90%",
        position: "fixed",
        top: "10px",
        left: "10px",
        zIndex: "5"
    },
    title: {
        color: 'rgba(255,255,255,0.8)',
        fontSize: '4.5rem',
        fontFamily : 'nunito',
        "@media (max-width: 720px)": {
            fontSize: '2.5rem',
          },
          "@media (min-width: 720px)": {
            fontSize: '4.5rem',
          },
    },
    container: {
        textAlign: 'center',
        "@media (min-width: 720px)": {
            position: "absolute",
            left: 0,
            right: 0,
            top:"20%",
            marginInline: "auto",
          },
        

    },
    expandDown: {
        color: 'rgba(255,255,255,0.8)',
        position: "relative",
        zIndex: "2",
        fontSize: "50px"
    },
    navigation: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",

        '& .MuiButton-root' : {
            color: "white",
            opacity: "80%",
            fontFamily: 'nunito',
        },
        position: "absolute",
        top: "10px",
        right: "10px",

        "@media (max-width: 720px)": {
            display: "none",
          },
    },
    navbar: {
        width: "100%",
        height: "10%",
        "@media (max-width: 720px)": {
            backgroundColor: "rgba(0,0,0,1)",
            position: "fixed",
            zIndex: "3"
          },

    },
    welcomeMessage: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    hamburgerMenu: {
        position: "absolute",
        right: "0",
        "@media (min-width: 720px)": {
            display:"none"
          },

    }
}));

const ITEM_HEIGHT = 48;


function WelcomeScreen(props) {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.navbar}>
                <div className={classes.navigation}>
                        <Scroll to="about-text"  smooth={true}>
                            <Typography component={Button} >O nama</Typography>
                        </Scroll>
                        <Scroll to="form"  smooth={true}>
                            <Typography component={Button} >Prijavi se</Typography>
                        </Scroll>
                        <Typography component={Button} href="https://www.mojsprat.com/login">Uloguj se</Typography>
                </div>
                <div className={classes.hamburgerMenu}>
                    <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    >
                        <MenuIcon className={classes.expandDown} />
                    </IconButton>
                    <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                            // backgroundColor: "rgba(0,0,0,0.9)",
                            // color: "white"
                            marginBlockStart: "42px",
                            marginInlineStart: "16px"
                        }
                    }}
                    >
                    <MenuItem>
                    <Scroll to="about-text"  smooth={true}>
                            <Typography component={Button} >O nama</Typography>
                        </Scroll>
                    </MenuItem>

                    <MenuItem>
                        <Scroll to="form"  smooth={true}>
                                <Typography component={Button} >Prijavi se</Typography>
                        </Scroll>
                    </MenuItem>
                    <MenuItem>
                        <Typography component={Button} href="https://www.mojsprat.com/login">Uloguj se</Typography>
                    </MenuItem>


                    </Menu>
                </div>
            </div>
            <div className={classes.appBarTitle}>
                <img src={process.env.PUBLIC_URL + '/assets/logo.png'} />
            </div>
            <div className={classes.welcomeMessage}>
                <div className={classes.container}>
                        <h1 className={classes.title}>
                            Vi posedujete, <br/>Vi kontrolišete
                        </h1>
                        <Scroll to="about-text" smooth={true}>
                        <IconButton>
                            <ExpandMoreIcon className={classes.expandDown}/>
                        </IconButton>
                        </Scroll>
                </div>
            </div>
        </div>

        // <div className={classes.root}>
        //     <div className={classes.navbar}>
            
               
                
        //     </div>

            
        // </div>
        // <div className={classes.root}>
        //     <div className={classes.navbar}>
                
        //     </div>
        // </div>
    );
}

export default WelcomeScreen;