import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    "@media (max-width: 720px)": {
      width: "95%"
    },
    "@media (min-width: 720px)": {
      width: "58%",
    },
    background: "rgba(0,0,0,0.8)",
  },
  description: {
      fontFamily : 'Nunito',
      color: '#ddd',
      marginInline : '1.5rem',
      marginBlock : '1rem',
      textAlign: "justify",
      "@media (max-width: 720px)": {
        fontSize: '0.9rem',
      },
      "@media (min-width: 720px)": {
        fontSize: '1.1rem',
      },
      
  }
});

export default function OutlinedCard() {
  const classes = useStyles();

  return (
        <Card className={classes.root} variant="outlined" id="about-text">
        <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                Platforma MojSprat je sofisticirana softverska platforma koja pruža brzu i efikasnu saradnju između stanara, različitih pružalaca usluga kao i upravnika i svih ostalih entiteta neophodnih za kvalitetno održavanje i upravljanje zgradom.<br/><br/>
                Stanovanje u zgradi može biti propraćeno raznim problemima: prljavom zgradom, ižvrljanim zidovima, problemima sa instalacijama kao i mnogim drugim  problemima. Sa druge strane, ukoliko je zgrada čista, okrečena ili ima lep dekor ili biljke, sve to može učiniti da se značajno poboljša kvalitet stanovanja na koji utiču razne okolnosti pored prostora u kome živimo. Šta je rešenje i kako poboljšati kvalitet zgrade u kojoj živimo?<br/><br/>
                Da li je upravnik iz redova stanara ili profesionalni upravnik bolje rešenje?<br/>
                Da li profesionalni upravnici imaju previše zgrada i ne žele/ne mogu da se posvete  svakoj zgradi?<br/>
                Da li upravnici iz redova stanara nemaju dobra rešenja i prakse da kvalitetno vode zgradu?<br/>
                Da li bi trebalo i mi kao stanari da vidimo koje su opcije za različite probleme, da li imamo šansu da utičemo i upravljamo, da li možemo da sami dajemo ponude, da li bi to koristilo?<br/><br/>
                Ovo su pitanja koja se nameću mnogim stanarima. Iako postoje zakonske osnove za rešavanje pomenutih problema, nakon urađene opsežne analize saznali smo da je trenutna situacija sledeća: <br/><br/>

                • sastanci skupštine stanara su veoma neefikasni<br/>
                • stanari nemaju vremena i ne žele da idu na sastanke<br/>
                • finansije zgrade su netransparentne i nejasne<br/>
                • informacije o zgradi su često nedostupne<br/>
                • komunikacija je često neproduktivna<br/>
                • postoje problemi sa naplatom i kod stanara i kod izvođača<br/>
                • nedostatak ponuda za određene probleme<br/>
                • ne uključivanje stanara u rad zgrade<br/>
                • nemogućnost stanara da učestvuju u donošenju odluka.<br/><br/>

                Postoji mnogo različitih učesnika (fizičkih lica, malih biznisa i institucija) koji su povezani sa sektorom nekretnina: od banaka, građevinskih firmi, mnogih uslužnih firmi i raznih institucija. Ono što je zajedničko jeste da svaki entitet u tom lancu kontroliše svoj deo i profitira (ako je komercijalni entitet) . Banke  poseduju novac, kontrolišu hipoteke, nude kredite i profitiraju od njih, stanodavci poseduju stanove, kontrolišu ih i profitiraju od rente. Iako u stambenim zgradama vlasnici stanova poseduju zgradu, šta je sa kontrolom i profitom kod upravljanja zgradama? Da li bi trebalo vlasnici stanova da brinu o njemu, na prvi pogled izgleda ne. Odjednom se postavlja pitanje da li su nedostatak transparentnosti i kontrole nezavisni u odnosu na kvalitet usluga, da li profit upravnika može uticati na lošije usluge održavanja.  Da li higijeničarke u agencijama za čišćenje imaju humane uslove i da li profit agencija utiče da imaju što veći broj zgrada i što manje vremena da odvoje po zgradi, da li se isplati da kvalitetno rade svoj posao?<br/><br/>
                Kao što je booking napravio revoluciju i izbacio posrednike u procesu izdavanja nekretnina, na sličan način i Swissility program po kome radi MojSprat, uz pomoć interakcije stanara omogućava odabir kvalitetnih usluga i proizvoda, ali i kvalitetnije upravljanje i zadovoljnije stanare.<br/><br/>
                Sistematizovana interakcija sa stanarima kao i druge funkcionalnosti ove jedinstvene platforme omogućavaju osim boljeg vođenja zgrade i značajno olakšanje kako pružaocima usluga tako i upravniku.<br/><br/>
                Bez obzira da li zgradu vodi profesionalni upravnik ili ne, platforma MojSprat omogućava rešenje gorenavedenih problema i da na jedinstven način uštedite vreme vlasnicima stanova tako što umesto gubljenja vremena na sastancima omogućava da jednim klikom odaberete ponudu koja vama odgovara. Platforma ima mehanizam automatizovanog glasanja i  omogućava efikasno donošenje odluka. Takođe, sve informacije o zgradi i bitnim dešavanjima su u potpunosti transparente i možete ih naći na jedom mestu. Ceo sistem je integrisan sa finansijskim modulom napravljenim namenski za vođenja zajedničkih finansija. Ovo je samo deo funkcionalnosti koje platforma pruža.<br/><br/>
                Saznajte kako funkcioniše sistem ako sami stanari imaju mogućnost da vide sve informacije i da donose odluke.<br/><br/>
                Prijavite se na platformu MojSprat i jednostavno isprobajte.<br/><br/>
            </Typography>      
        </CardContent>
        </Card>
  );
}
