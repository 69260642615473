import React from 'react';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import TenantForm from './TenantForm'
import ManagerForm from './ManagerForm'
import ServiceProviderFrom from './ServiceProviderForm'


const useStyle = makeStyles(theme => ({
    root: {
        height : '32.5rem',
        marginBlock: "0.625rem",
        padding: "1.25rem 0.3125rem",
        background: "rgba(0,0,0,0.8)",
        fontFamily: 'Nunito',
        color: 'white',
        "@media (max-width: 720px)": {
            width: "95%"
          },
          "@media (min-width: 720px)": {
            width: "58%",
          },
    
    },
}));



function Form(props) {
    const classes = useStyle();
    const [value, setValue] = React.useState(0);
    const handleTabs = (event,value) => {
        setValue(value);
    };


    return (
        <>
        <div className = {classes.root} id="form">
        <Tabs value={value} onChange={handleTabs}>
            <Tab label="Stanari" />
            <Tab label="Upravnici" />
            <Tab label="Pružaoci usluga" />
        </Tabs>
        <TabPanel value={value}></TabPanel>
        </div>
        </>
    );
}

function TabPanel(props)
{
    const {value} = props;

    if(value === 0)
        return (<TenantForm/>);
    else if (value === 1)
        return (<ManagerForm/>)
    else if (value === 2)
        return (<ServiceProviderFrom/>)
}

export default Form;