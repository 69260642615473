import './App.css';
import React from 'react';
import { CssBaseline, makeStyles } from '@material-ui/core'
import About from './components/About'
import WelcomeScreen from './components/WelcomeScreen';

const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: '100vh',
      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/background.jpg'})`,
      // backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      display: "flex",
      flexDirection: "column",
    }
}));

function App() {

  const classes = useStyles();

  return (
      <>
    <div className={classes.root}>
      <CssBaseline/>
      <WelcomeScreen/>
      <About/> 
    </div>
    </>
  );
}

export default App;
