import React from 'react';
import { makeStyles } from '@material-ui/core'
import TextCard from './TextCard'
import Form from './Form'

const useStyles = makeStyles(theme => ({
    root: {
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    }

}));

function About(props) {
    const classes = useStyles();
    return (
        <div className={classes.root} >
        <TextCard></TextCard>
        <Form></Form>
        </div>
    );
}

export default About;