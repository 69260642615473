import React,{useState} from "react";
import { Grid, TextField, makeStyles, Card, Button, CardContent, Switch, FormControlLabel } from "@material-ui/core";
import { useForm } from 'react-hook-form'
import { red } from "@material-ui/core/colors";
const useStyle = makeStyles(theme => ({
    root: {
        '& .MuiFormLabel-root' : {
            color: "#E0E0E0",
            fontFamily: 'Nunito'
        },
        '& .MuiOutlinedInput-input': {
            color: "#E0E0E0",
            fontFamily: 'Nunito'
        },
        '& .MuiTypography-root' : {
            color : '#E0E0E0',
            fontFamily: 'Nunito',
            '&.MuiFormControlLabel-label' : {
                color: '#E0E0E0',
            }
            
        },
        '& .MuiButtonBase-root' : {
            opacity : 0.9,
        },
        '& label.Mui-focused': {
            color: '#E0E0E0',
            fontFamily: 'Nunito'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#757575',
                
            },
            '&:hover fieldset': {
                borderColor: '#757575',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#5C6BC0',
            },

        },

        maxWidth : '62.5rem',
        marginBlock: "0.625rem",
        padding: "1.25rem 0.3125rem",
        background: "rgba(0,0,0,0)",
        fontFamily: 'Nunito'
    
    },
    formLabel: {
        fontFamily : 'Nunito',
        fontSize: '2rem',
        color: 'white',
    },
}));

const sendRequest = (data) => {

    let requestInit = {
        method : "POST",
        body : JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    }

    fetch("/api/landing/managers",requestInit)
    .then(res => console.log(res))
    .catch(error => console.log(error));



};

export default function Form() {

    const classes = useStyle();
    const {register, handleSubmit} = useForm();
    const [checked, setChecked] = useState(false);

    const toggleChecked = () => {
        setChecked((prev) => !prev);
      };


    // (data) => {axios.post('/api/landing/tenants',data).then(response => console.log(response)).catch(error => console.log(error))}

    return (

        
        
        <div className={classes.root}>
                {/* <Typography gutterBottom variant="h5" className={classes.formLabel}> Za upravnike </Typography> */}
                <form onSubmit = {handleSubmit(sendRequest)} >
                <Grid container spacing={1}>                 
                    <Grid xs={12} item>
                        <TextField
                            variant="outlined"
                            label="Grad"
                            placeholder="Unesite ime grada"
                            fullWidth
                            {...register('city')}
                            style={{borderColor : "red"}}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            variant="outlined"
                            label="Adresa"
                            placeholder="Unesite vasu adresu"
                            fullWidth
                            {...register('address')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            type="email"
                            variant="outlined"
                            label="Email"
                            placeholder="Unesite vas email"
                            fullWidth
                            {...register('email')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            variant="outlined"
                            label="Broj telefona"
                            placeholder="Unesite vas broj telefona"
                            fullWidth
                            {...register('phone')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <FormControlLabel
                            control={<Switch checked={checked} onChange={toggleChecked}/>}
                            label="Samostalni"
                            labelPlacement="start"
                            {...register('independent')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            disabled = {checked}
                            variant="outlined"
                            label="Firma"
                            placeholder="Unesite ime firme za koju radite"
                            fullWidth
                            {...register('firm')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth>Prijavite se</Button>
                    </Grid>
                    
                </Grid>
                </form>
                </div>

    );
}